import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import dayjs from "dayjs";

import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import AddOrEditHotdeskBooking from "../../resources/AdminUI/HotdeskBookings/AddOrEditHotdeskBooking";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import AddOrEditRoomBooking from "../../resources/AdminUI/RoomBookings/AddOrEditRoomBooking";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { firstLetterToLowerCase, String_Format } from "../../utils/utils";
import { formatDateInZone, isSameDay, DATE_FORMAT } from "../../utils/date";
import { RoomBookingTypes } from "../../enums/RoomBookings/RoomBookingTypes";
import RoomBookingTypeList from "../../resources/AdminUI/RoomBookings/RoomBookingTypeList";
import PriceLabel from "../Shared/PriceLabel";

const formatDate = (date: string) => dayjs(date).format("DD MMM YYYY, HH:mm");

const buildBookingInfoCell = (row: any) => {
  const { bookingTypeId, startsAt, endsAt, timezone, bookingAmount } =
    row.original;
  const type = RoomBookingTypes[bookingTypeId];
  const typeName = RoomBookingTypeList.Resources[firstLetterToLowerCase(type)];

  switch (bookingTypeId) {
    case RoomBookingTypes.Hourly:
      return (
        <div className="column-xlarge">
          <p>{typeName}</p>
          <p className="timestamp">
            {formatDateInZone(startsAt, timezone)} -{" "}
            {formatDateInZone(endsAt, timezone)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
    case RoomBookingTypes.Daily:
      return (
        <div className="column-xlarge">
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.bookingAmount,
              typeName,
              bookingAmount
            )}
          </p>
          <p className="timestamp">
            {isSameDay(startsAt, endsAt)
              ? formatDateInZone(startsAt, timezone, DATE_FORMAT)
              : formatDateInZone(startsAt, timezone, DATE_FORMAT) +
                " - " +
                formatDateInZone(endsAt, timezone, DATE_FORMAT)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
    case RoomBookingTypes.Monthly:
      return (
        <div className="column-xlarge">
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.bookingAmount,
              typeName,
              bookingAmount * 30
            )}
          </p>
          <p className="timestamp">
            {formatDateInZone(startsAt, timezone, DATE_FORMAT)} -{" "}
            {formatDateInZone(endsAt, timezone, DATE_FORMAT)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
  }
};

// Refactor to accept a parameter to show or hide the actions column
export const getRoomBookingsColumns = (
  showActionsColumn: boolean
): ColumnDef<IRoomBookingsItem>[] => {
  const columns: ColumnDef<IRoomBookingsItem>[] = [
    {
      header: "VISIT ID",
      cell: ({ row }) => (
        <div className="column-xsmall">
          <p>{String_Format(RoomBookingsGrid.Resources.id, row.original.id)}</p>
        </div>
      ),
    },
    {
      header: "REQUEST TIMESTAMP",
      cell: ({ row }) => (
        <div className="column-medium">
          <p className="timestamp">{formatDate(row.original.createdAt)}</p>
        </div>
      ),
    },
    {
      header: "USER",
      cell: ({ row }) => (
        <div className="column-large">
          <Link
            className="table-item-link"
            to={ApplicationPathBuilder.ViewUserDetails(row.original.userId)}
          >
            {`${row.original.userFirstName} ${row.original.userLastName}`}
          </Link>
          <div>
            {AddOrEditRoomBooking.Resources.userId} #{row.original.userId}
          </div>
        </div>
      ),
    },
    {
      header: "CUSTOMER",
      cell: ({ row }) => (
        <div className="column-medium">
          <Link
            className="table-item-link"
            to={ApplicationPathBuilder.ViewCustomerDetails(
              row.original.userCompanyId
            )}
          >
            {row.original.userCompanyName}
          </Link>
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.customerId,
              row.original.userCompanyId
            )}
          </p>
        </div>
      ),
    },
    {
      header: "SPACE",
      cell: ({ row }) => (
        <div className="column-large">
          <Link
            className="table-item-link"
            to={ApplicationPathBuilder.ViewSpaceDetails(
              row.original.workspaceId.toString()
            )}
          >
            {row.original.workspaceName}
          </Link>
          <p>
            {row.original.workspaceCountryFlag} {row.original.workspaceCityName}{" "}
            | {AddOrEditHotdeskBooking.Resources.spaceId}:{" "}
            {row.original.workspaceId}
          </p>
        </div>
      ),
    },
    {
      header: "ROOM",
      cell: ({ row }) => (
        <div className="column-medium">
          <p className="room-name">{row.original.roomName}</p>
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.roomId,
              row.original.roomId
            )}
          </p>
        </div>
      ),
    },
    {
      header: "BOOKING INFO",
      cell: ({ row }) => buildBookingInfoCell(row),
    },
    {
      header: "PRICE",
      cell: ({ row }) => (
        <div className="column-small">
          <PriceLabel
            price={row.original.price}
            currency={row.original.currency}
          />
        </div>
      ),
    },
    {
      header: "STATUS",
      cell: ({ row }) => (
        <div className="column-small">
          <p>{row.original.statusName}</p>
        </div>
      ),
    },
  ];

  if (showActionsColumn) {
    columns.unshift({
      header: "ACTIONS",
      cell: ({ row }) => (
        <div className="flex space-x-2">
          <Link
            to={ApplicationPathBuilder.EditRoomBooking(row.original.roomId)}
          >
            <Edit size={20} />
          </Link>
        </div>
      ),
    });
  }

  return columns;
};
